export const PUBLICO_TODOS = "Todos";
export const PUBLICO_AFILIADOS = "Solo afiliados";
export const PUBLICO_AFILIADOS_EMPRESA_COMFENALCO =
  "Solo afiliados de Empresa Comfenalco";
export const PUBLICO_AFILIADOS_MENOS_EMPLEADOS =
  "Solo afiliados menos empleados de Comfenalco";
export const COMPRA_INDIVIDUAL_ACTIVA = "A";
export const COMPRA_INDIVIDUAL_INACTIVA = "I";
export const ES_EMBEBIDO = "S";
export const NO_EMBEBIDO = "N";
export const OPORTUNIDAD = "S";
export const TITULAR = "T";
export const PARTICULAR = "Particular";
export const ORIGEN_WEB = "Web";
export const ABREVIACION_ORIGEN_WEB = "W";
export const PARAM_NO_INFO_VIVIENDA = "NO_INFO_VIVIENDA";
export const PARAM_LINK_NO_INFO_VIVIENDA = "LINK_NO_INFO_VIVIENDA";
export const PARAM_URL_VIDEO_POSTULACION_VIVIENDA = "URL_VIDEO_POSTULACION";
export const PARAM_URL_FORM_POSTULACION_VIVIENDA = "URL_FORMU_VIVIE";
export const PARAM_MENSAJE_CUOTAS_EDUCACION = "MENSAJE_CUOTAS_EDUCACION"
export const PARAM_INFO_RADICACION_SUB_VIVIENDA =
  "INFO_RADICACION_SUB_VIVIENDA";

export const HOUSING_ENTITLEMENT_ID = "8";
export const CAMPO_S = "S";
export const PASSPORT_DOCUMENT_TYPE = "PA";
export const YOUTUBE_URL_FORMATS = [
  "youtube.com/watch?v=",
  "youtu.be/",
  "youtube.com/embed/",
];

export const DISABLED_STATUS_HOUSING_SUBSIDY = "Inhabilitado";
export const PARAM_RANGO_FECHA = "RANGO_FECHAS_SM";
export const PARAM_MENSAJE_ADJUDICACION_KIT_ESCOLAR =
  "COMENTARIO_KIT_ADJUDICACION";
export const PARAM_MENSAJE_POSTULACION_KIT_ESCOLAR =
  "COMENTARIO_KIT_POSTULACION";
export const PARAM_ERROR_USUARIO_NO_CORRESPONDE =
  "MENSAJE_ERROR_USUARIO_NO_CORRESPONDE";
export const PARAM_NO_KIT_ESCOLAR = "MENSAJE_ERROR_NO_HAY_KIT_ESCOLAR";
export const PARAM_LINK_ERROR_USUARIO_NO_CORRESPONDE =
  "ENLACE_ERROR_USUARIO_NO_CORRESPONDE";
export const PARAM_LINK_NO_KIT_ESCOLAR = "ENLACE_ERROR_NO_HAY_KIT_ESCOLAR";
export const PARAM_LINK_NO_AGENDAR_CITA = "URL_TURNEROS";
export const PARAM_MENSAJE_FECHA_PAGO_RESERVA = "MENSAJE_FECHA_PAGO_RESERVA";
export const PARAM_CANTIDAD_SERVICIOS_PAGINADO = "CANTIDAD_SERVICIOS_PAGINADO";
export const COMPRA_SERVICIOS_PRIMER_PARRAFO = "COMPRA_SERVICIOS_PRIMER_PARRAFO"
export const COMPRA_SERVICIOS_SEGUNDO_PARRAFO = "COMPRA_SERVICIOS_SEGUNDO_PARRAFO"
export const CUPO_CREDITO_PRIMER_PARRAFO = "CUPO_CREDITO_PRIMER_PARRAFO"
export const CUPO_CREDITO_SEGUNDO_PARRAFO = "CUPO_CREDITO_SEGUNDO_PARRAFO"
export const LIBRE_INVERSION_PARRAFO = "LIBRE_INVERSION_PARRAFO"
export const COMPRA_DE_CARTERA_PARRAFO = "COMPRA_DE_CARTERA_PARRAFO";
export const CUPO_CREDITO_MV_A = "CUPO_CREDITO_MV_A";
export const COMPRA_SERVICIO_MV_A = "COMPRA_SERVICIO_MV_A";
export const CUPO_CREDITO_MV_B = "CUPO_CREDITO_MV_B";
export const COMPRA_SERVICIO_MV_B = "COMPRA_SERVICIO_MV_B";
export const INFO_IMPORTANTE_CREDITO = "INFO_IMPORTANTE_CREDITO";
export const INFO_IMPORTANTE_COMPRA = "INFO_IMPORTANTE_COMPRA";



// Constantes para ind origen
export const IND_ORIGEN_EDUCACION = "E";
export const IND_ORIGEN_ZEUS = "Z";
export const IND_ORIGEN_EVENTOS = "V";
export const IND_ORIGEN_CONVENIOS = "C";
export const IND_ORIGEN_EXTERNO = "X";
export const IND_ORIGEN_COLEGIO = "O";
export const IND_ORIGEN_ESPACIOS = "D";
export const IND_ORIGEN_HOTELES = "H";

// Tipos de pantallas
export const ST_SERVICIO = "tipoServicio";
export const ST_RE_CAPTCHA = "tipoServicioRecaptcha";
export const ST_ESPACIOS = "tipoEspacios";
export const ST_HOTEL = "hotel";
export const ST_SUB_VIVIENDA = "subsidioVivienda";
export const ST_SUB_MONETARIO = "subsidioMonetario";
export const ST_POSTULACION = "postulacion";
export const ST_CONSULTA_SUBSIDIO = "consultSubsidio";
export const ST_CUPOS = "cupos";
export const ST_SALDOS = "saldos";
export const ST_AGENDAR_CITA = "agendarCita";
export const ST_CONSULTA_PQRS = "consultaPQRS";
export const ST_CREA_PQRS = "crearPQRS";
export const ST_KIT_ESCOLAR = "kitEscolar";
export const ADMINISTRADOR = 'Administrador';
export const AGENDAR_CITA = 'agendarCita';

// Estados historial de compra
export const ESTADO_COMPRA_AGENDADA = "Agendada";
export const ID_ESTADO_COMPRA_AGENDADA = 10;
export const ID_ESTADO_COMPRA_POSTULADA = 11;
// Permisos para venta individual
export const OPCION_VENTA_INDIVIDUAL = 9;
export const TIPO_USUARIO = "C";

//hoteles
export const MAYORIA_EDAD_HOTEL = 7;
//URL's
export const SECURITY_URLS: string[] = ['paySale', 'shoppingCar', 'compraCero', 'reservarHotel', 'reservarArea'];



