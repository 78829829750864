import React, { useEffect, useState } from 'react'
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment, { Moment } from 'moment';
import 'moment/locale/es';
import { useGenericData } from 'hooks/useGenericData';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CompraUsuario, EnvioCrmLeadRequest, ParticularForm, RateFormLight, ServiceDetailLight, ShoppingCartWithValue } from 'models/lightForm';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { RatesFormLightRequest } from 'hooks/ratesFormLightRequest';
import { useRates } from 'hooks/useRates';
import { getAgeFromDate } from 'utils/FunctionUtilities';
import { useShoppingCar } from 'hooks/useShoppingCar';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { setSuccess } from 'store/slices/status';
import { useUser } from 'hooks/useUser';
import { useParam } from 'hooks/useParam';
import { get } from 'http';
import { Loading } from 'components/Loading';
import { IND_ORIGEN_EDUCACION, PARAM_MENSAJE_CUOTAS_EDUCACION } from 'utils/constants';
interface ParticularFormProps {
    setStep: React.Dispatch<React.SetStateAction<number>>,
    setInfoUserShoppingData: React.Dispatch<React.SetStateAction<any>>,
    admissionData?: any,
    serviceDetail?: ServiceDetailLight,
    setRateSelected?: React.Dispatch<React.SetStateAction<any>>,
    setPreviousstep: React.Dispatch<React.SetStateAction<number>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    isLoading: boolean
}

export const ParticularUserForm = ({ setStep, setInfoUserShoppingData, admissionData, serviceDetail, setRateSelected, setPreviousstep, setLoading, isLoading }: ParticularFormProps) => {
    const dispatch = useDispatch<AppDispatch>();
    const { handleSubmit, register, formState: { errors }, setValue, getValues } = useForm<ParticularForm>()

    const { getIdentificationsTypes } = useGenericData();

    const [identificationTypes, setIdentificationTypes] = useState<any[]>([]);

    const [personAge, setPersonAge] = useState<number>();

    const [rateFound, setrateFound] = useState<RateFormLight>();

    const { getRatesFormLight } = useRates();

    const { shoppingCartWithValueLightForm, paySale } = useShoppingCar();

    const { envioCrmLead } = useUser();

    const { getParamData } = useParam();

    const [mensajeCuotas, setMensajeCuotas] = useState('');


    const findRate = async () => {

        if (serviceDetail?.fechaServicio && admissionData) {

            let fechaServicio: Date = serviceDetail.fechaServicio;

            ////console.log('fecha del servicio----')
            ////console.log(fechaServicio)
            ////console.log(serviceDetail);
            
            // fecha a string dia/mes/año
            let fechaServicioString: string = fechaServicio.getDate() + '/' + (fechaServicio.getMonth() + 1) + '/' + fechaServicio.getFullYear();

            const birthDate = getValues('birthDate')


            // moment to date
            const dateConverted: Date = new Date(birthDate)
            // fecha a string dia/mes/año
            let birthDayString = dateConverted?.getFullYear() + "-" + (dateConverted?.getMonth() + 1) + '-' + +dateConverted?.getDate();


            let rateRequest: RatesFormLightRequest = {
                idServicio: serviceDetail?.idServicio,
                numeroIdentificacion: admissionData?.numeroIdentificacion,
                tipoIdentificacion: admissionData?.tipoIdentificacion,
                fechaNacimiento: birthDayString,
                fechaServicio: fechaServicioString
            }



            const getRatesFound: RateFormLight = await getRatesFormLight(rateRequest);


            if (getRatesFound) {
                setrateFound(getRatesFound);
            }
        }

    }


    useEffect(() => {
        setPreviousstep(0)

        const onGetGetIdentificationsTypes = async () => {
            const identificationTypesResp = await getIdentificationsTypes();
            setIdentificationTypes(
                identificationTypesResp?.identificationTypes
                    ? identificationTypesResp?.identificationTypes
                    : []
            );
        };

        const getParam = async () => {
            const mensajeCuotasParam = await getParamData(PARAM_MENSAJE_CUOTAS_EDUCACION);
            mensajeCuotasParam?.data?.valorParametro &&
                setMensajeCuotas(mensajeCuotasParam?.data?.valorParametro);
        }

        getParam();
        onGetGetIdentificationsTypes();
    }, []);

    const handleFormSubmit: SubmitHandler<ParticularForm> = async (
        data: ParticularForm
    ) => {
        setLoading && setLoading(true);
        // validate if form is valid        
        if (Object.keys(errors).length === 0 && rateFound && serviceDetail && admissionData) {

            //let serviceDetail.campoJson = JSON.parse(serviceDetail.campoJson);

            let servFechaInicial = serviceDetail.campoJson.servFechaInicial;
            let servFechaFinal = serviceDetail.campoJson.servFechaFinal;

            // convertir fechas a formato anioo/mes/dia
            let fechaInicialDate = servFechaInicial ? new Date(servFechaInicial) : new Date();
            let fechaFinalDate = servFechaFinal ? new Date(servFechaFinal) : new Date();

            let fechaInicialString = "";
            let fechaFinalString = "";
            if (fechaInicialDate && fechaFinalDate) {

                fechaInicialString = fechaInicialDate.getFullYear() + '/' + (fechaInicialDate.getMonth() + 1).toString().padStart(2, '0') + '/' + fechaInicialDate.getDate().toString().padStart(2, '0');
                fechaFinalString = fechaFinalDate.getFullYear() + '/' + (fechaFinalDate.getMonth() + 1).toString().padStart(2, '0') + '/' + fechaFinalDate.getDate().toString().padStart(2, '0');
            }
            if (setRateSelected) {
                setRateSelected(rateFound);
            }


            const edad = getAgeFromDate(new Date(data.birthDate));

            const compraUsuarios: CompraUsuario[] = getCompraUsuarios();

            let dataPaymentShoppingCart: ShoppingCartWithValue = {
                valorTotal: rateFound?.valor,
                porcentajeDcto: Number(rateFound?.porcentajeDcto),
                origenCompra: 'W',
                fechaInicioServicio: fechaInicialString,
                fechaFinServicio: fechaFinalString,
                idServicio: serviceDetail?.idServicio,
                cantidad: compraUsuarios.length,
                medioCompra: 'Web',
                centroServicio: String(serviceDetail?.idCentroServicio),
                compraUsuarios: compraUsuarios,
                facturaUsuarios: null,
                entrevista: null,
                reserva: null,
                listTiempoEspacio: null,
                afiliadoIdentificacion: getValues('documentNumber') ? getValues('documentNumber') : admissionData?.numeroIdentificacion,
                afiliadoTipoIden: getValues('documentType') ? getValues('documentType') : admissionData?.tipoIdentificacion,
                nombreSolicitante: getValues('fullName'),
                edad: edad,
                email: getValues('email')
            }
            //console.log(dataPaymentShoppingCart)
            const shoppingCartResponse = await shoppingCartWithValueLightForm(dataPaymentShoppingCart);

            if (shoppingCartResponse?.status === 200) {

                let idCompra = shoppingCartResponse?.data?.idCompra;
                handlePaySale(idCompra)
            } else setLoading && setLoading(false);
        }

    };

    const handlePaySale = async (idSale: any) => {

        const getParamUrlPasarela = await getParamData("URL_PASARELA");

        const paySaleResultInfo = await paySale(idSale);
        //setLoading && setLoading(false);
        if (getParamUrlPasarela?.data && paySaleResultInfo) {
            const dataParameter = getParamUrlPasarela?.data;
            //console.log('replace')

            window.location.replace(dataParameter + paySaleResultInfo?.infoPago);
        }
    };

    const getCompraUsuarios = () => {

        let compraUsuarios: any = [];

        //    get form data
        const birthDate = getValues("birthDate");



        // convertir fecha a formato anio/mes/dia
        // obtener edad
        let fechaNacimiento = moment(birthDate).format('YYYY/MM/DD');
        const edad = getAgeFromDate(new Date(birthDate));

        if (admissionData && rateFound) {


            let usuarioCompra: CompraUsuario = {
                tipoIdentificacion: admissionData?.tipoIdentificacion,
                numIdentificacion: admissionData?.numeroIdentificacion,
                nombre: getValues('fullName'),
                correo: getValues('email'),
                fechaNacimiento: fechaNacimiento,
                fechaExpedicion: getValues('expDate') ? moment(getValues('expDate')).format('YYYY/MM/DD') : '',
                genero: getValues('sex'),
                edad: edad,
                valor: rateFound?.valor,
                porcentajeDcto: rateFound?.porcentajeDcto,
                idCate: rateFound?.idCategoriaUsuarioCdaCategoriaUsuario,
                edadMinima: rateFound?.edadMinima,
                edadMaxima: rateFound?.edadMaxima,
                oportunidad: 'S',
                celular: getValues('cellPhone'),
                direccion: getValues('direccion'),
                primerNombre: getValues('fullName'),
                segundoNombre: getValues('secondName'),
                primerApellido: getValues('surname'),
                segundoApellido: getValues('secondSurname'),
            };
            compraUsuarios.push(usuarioCompra);
        }

        return compraUsuarios;

    }

    const handleDateChange = (date: Moment | null) => {
        setValue(`birthDate`, date);


        if (date) {
            const currentDate = moment();
            const age = currentDate.diff(date, 'years');

            if (age > 18) {

                // set document type and document number null
                setValue(`documentType`, '');
                setValue(`documentNumber`, '');
            }

            setPersonAge(age);

            findRate();
        }

    }

    const handleExpDateChange = (date: Moment | null) => {
        setValue(`expDate`, date);


        if (date) {
            const currentDate = moment();
            const age = currentDate.diff(date, 'years');

            //setPersonAge(age);

            findRate();
        }

    }

    const handleDateChangePayer = (date: Moment | null) => {
        setValue(`birthDatePayer`, date);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // Permitir solo números
        if (event.key !== 'Backspace' && isNaN(Number(event.key))) {
            event.preventDefault();
        }
    };



    const enviarCrmLead = async () => {

        if (admissionData && rateFound && serviceDetail) {

            //console.log(getValues('birthDate'))
            let birthDate = new Date(getValues('birthDate'));

            // Formateo de fecha de nacimiento de acudiente a anio-mes-dia con cero a la izquierda
            let fechaNacimientoAcudiente = birthDate.getFullYear() + "-" + (birthDate.getMonth() + 1).toString().padStart(2, '0') + '-' + birthDate.getDate().toString().padStart(2, '0');


            let envioCrmLeadRequest: EnvioCrmLeadRequest = {
                tipoIdentificacionAfiliado: admissionData?.tipoIdentificacion,
                numeroIdentificacionAfiliado: admissionData?.numeroIdentificacion,
                nombreAfiliado: getValues('fullName'),
                fechaNacimientoAfiliado: new Date(getValues('birthDate')).toISOString(),
                celularAfiliado: getValues('cellPhone'),
                correoAfiliado: getValues('email'),
                idServicio: serviceDetail?.idServicio,
                numeroIdentificacionAcudiente: admissionData?.numeroIdentificacion,
                tipoIdentificacionAcudiente: admissionData?.tipoIdentificacion,
                nombreAcudiente: getValues('fullName'),
                celularAcudiente: getValues('cellPhone'),
                correoAcudiente: getValues('email'),
                fechaNacimientoAcudiente: fechaNacimientoAcudiente
            }



            if (getValues('documentNumber') && getValues('documentType')) {
                envioCrmLeadRequest.numeroIdentificacionAfiliado = getValues('documentNumber');
                envioCrmLeadRequest.tipoIdentificacionAfiliado = getValues('documentType');
                envioCrmLeadRequest.nombreAfiliado = getValues('namePayer');
                envioCrmLeadRequest.celularAfiliado = getValues('cellPhonePayer');
                envioCrmLeadRequest.correoAfiliado = getValues('emailPayer');
            }

            const envioCrmResponse = await envioCrmLead(envioCrmLeadRequest);

            if (envioCrmResponse) {
                dispatch(setSuccess("Se ha enviado la información de contacto correctamente"));
                setStep(0);

            }
        }

    }

    return (
        <div className="flex flex-wrap w-full">
            {//isLoading && <Loading />
            }
            <span className="flex w-full font-bold text-xl text-principal-250 pb-2 align-center justify-center items-center content-center">
                Detalles de la compra
            </span>
            <form
                onSubmit={handleSubmit(handleFormSubmit)}
                className="flex flex-wrap"
            >
                <span className="w-full font-regular text-md text-principal-250 pb-2">
                    Datos del solicitante del servicio
                </span>
                <div className="flex flex-row w-full">
                    <div className="flex flex-col w-1/2">
                        <span>Tipo de documento</span>
                        <span className="font-bold">{admissionData?.tipoIdentificacion}</span>

                    </div>

                    <div className="flex flex-col w-1/2">
                        <span>Número de identificación</span>
                        <span className="font-bold">{admissionData?.numeroIdentificacion}</span>

                    </div>
                </div>
                <div className='flex flex-wrap w-full mt-5'>
                    <div className="w-full sm:w-1/2 px-1 py-3">
                        <TextField
                            defaultValue={""}
                            className="w-full"
                            label="Primer Nombre"
                            {...register(`fullName`, {
                                required: "El primer nombre es obligatorio"
                            })}
                        />
                        {errors.fullName && (
                            <p className="text-red-500">
                                {String(errors?.fullName?.message)}
                            </p>
                        )}
                    </div>
                    <div className="w-full sm:w-1/2 px-1 py-3">
                        <TextField
                            defaultValue={""}
                            className="w-full"
                            label="Segundo Nombre"
                            {...register(`secondName`, {
                                //required: "El segundo nombre es obligatorio"
                            })}
                        />
                        {errors.secondName && (
                            <p className="text-red-500">
                                {String(errors?.secondName?.message)}
                            </p>
                        )}
                    </div>
                    <div className="w-full sm:w-1/2 px-1 py-3">
                        <TextField
                            defaultValue={""}
                            className="w-full"
                            label="Primer apellido"
                            {...register(`surname`, {
                                required: "El primer apellido es obligatorio"
                            })}
                        />
                        {errors.surname && (
                            <p className="text-red-500">
                                {String(errors?.surname?.message)}
                            </p>
                        )}
                    </div>
                    <div className="w-full sm:w-1/2 px-1 py-3">
                        <TextField
                            defaultValue={""}
                            className="w-full"
                            label="Segundo apellido"
                            {...register(`secondSurname`, {
                                //required: "El segundo apellido es obligatorio"
                            })}
                        />
                        {errors.secondSurname && (
                            <p className="text-red-500">
                                {String(errors?.secondSurname?.message)}
                            </p>
                        )}
                    </div>

                    <div className="w-full sm:w-1/2 mt-3 px-1">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                className="w-full"
                                format="DD/MM/YYYY"
                                disableFuture={true}
                                label="Fecha de nacimiento"
                                slots={{
                                    ...register(`birthDate`, {
                                        required: "La fecha de nacimiento es obligatoria",
                                    }),
                                }}
                                onChange={handleDateChange}
                            />
                        </LocalizationProvider>
                        {errors?.birthDate && (
                            <p className="text-red-500">
                                {String(errors?.birthDate?.message)}
                            </p>
                        )}
                    </div>

                    <div className="w-full sm:w-1/2 mt-3 px-1">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                className="w-full"
                                format="DD/MM/YYYY"
                                disableFuture={true}
                                label="Fecha de expedicion documento"
                                slots={{
                                    ...register(`expDate`, {
                                        required: "La fecha de expedicion es obligatoria",
                                    }),
                                }}
                                onChange={handleExpDateChange}
                            />
                        </LocalizationProvider>
                        {errors?.expDate && (
                            <p className="text-red-500">
                                {String(errors?.expDate?.message)}
                            </p>
                        )}
                    </div>

                    <div className="w-full sm:w-1/2 px-1 py-3">
                        <TextField
                            defaultValue={""}
                            className="w-full"
                            label="Correo electronico"
                            {...register(`email`, {
                                required: "El correo electronico es obligatorio",
                                pattern: {
                                    value: /^[a-zA-Z0-9.%+]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/,
                                    message: "Ingrese un correo electrónico válido"
                                }
                            })}
                        />
                        {errors?.email && (
                            <p className="text-red-500">
                                {String(errors?.email?.message)}
                            </p>
                        )}
                    </div>

                    <div className="w-full sm:w-1/2 px-1 py-3">
                        <TextField
                            defaultValue={""}
                            className="w-full"
                            label="Número de celular"
                            {...register(`cellPhone`, {
                                required: "El número de celular es obligatorio"
                            })}
                            onKeyDown={handleKeyDown}
                        />
                        {errors?.cellPhone && (
                            <p className="text-red-500">
                                {String(errors?.cellPhone?.message)}
                            </p>
                        )}
                    </div>

                    <div className="w-full sm:w-1/2 px-1 py-3">

                        <FormControl className="w-full">
                            <InputLabel id="genero">Género</InputLabel>
                            <Select
                                labelId="sexo"
                                label="Sexo"
                                defaultValue={undefined}
                                {...register(`sex`, {
                                    required: "El sexo es obligatorio",
                                })}
                            >
                                <MenuItem value="M">Masculino</MenuItem>
                                <MenuItem value="F">Femenino</MenuItem>
                            </Select>
                            {errors?.sex && (
                                <p className="text-red-500">
                                    {String(errors?.sex?.message)}
                                </p>
                            )}
                        </FormControl>

                    </div>

                    <div className="w-full sm:w-1/2 px-1 py-3">
                        <TextField
                            defaultValue={""}
                            className="w-full"
                            label="Direccion"
                            {...register(`direccion`, {
                                required: "La direccion es obligatoria"
                            })}
                        />
                        {errors?.direccion && (
                            <p className="text-red-500">
                                {String(errors?.direccion?.message)}
                            </p>
                        )}
                    </div>


                </div>

                {
                    (personAge != undefined && personAge < 18) &&
                    (<div className='flex flex-wrap w-full pt-3'>
                        <span className="w-full font-regular text-md text-principal-250 pb-2">
                            Datos del responsable de pago
                        </span>
                        <div className="w-full sm:w-1/2 px-1 py-3">
                            <FormControl className="w-full">
                                <InputLabel id="tipo-identificacion">
                                    Tipo de identificación
                                </InputLabel>
                                <Select
                                    labelId="tipo-identificacion"
                                    label="Tipo de identificación"
                                    defaultValue={"-1"}
                                    {...register(`documentType`, {
                                        required: "El tipo de identificación es obligatorio",
                                    })}
                                >
                                    <MenuItem value={'-1'}>
                                        Selecciona
                                    </MenuItem>
                                    {identificationTypes?.map((id, index) => (
                                        <MenuItem key={index} value={id?.ABREVIATURA}>
                                            {id?.DESCRIPCION}
                                        </MenuItem>
                                    ))}

                                </Select>
                                {errors?.documentType && (
                                    <p className="text-red-500">
                                        {String(errors?.documentType?.message)}
                                    </p>
                                )}
                            </FormControl>
                        </div>
                        <div className="w-full sm:w-1/2 px-1 py-3">
                            <TextField
                                defaultValue={""}
                                className="w-full"
                                label="Número de identificación"
                                {...register(`documentNumber`, {
                                    required: "El número de documento es obligatorio",
                                })}
                                onKeyDown={handleKeyDown}
                            />
                            {errors?.documentNumber && (
                                <p className="text-red-500">
                                    {String(errors?.documentNumber?.message)}
                                </p>
                            )}
                        </div>

                        <div className="w-full sm:w-1/2 px-1 py-3">
                            <TextField
                                defaultValue={""}
                                className="w-full"
                                label="Nombre"
                                {...register(`namePayer`, {
                                    required: "El nombre es obligatorio",
                                })}
                                onKeyDown={handleKeyDown}
                            />
                            {errors?.namePayer && (
                                <p className="text-red-500">
                                    {String(errors?.namePayer?.message)}
                                </p>
                            )}
                        </div>

                        <div className="w-full sm:w-1/2 mt-3 px-1">
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    className="w-full"
                                    format="DD/MM/YYYY"
                                    disableFuture={true}
                                    label="Fecha de nacimiento"
                                    slots={{
                                        ...register(`birthDatePayer`, {
                                            required: "La fecha de nacimiento es obligatoria",
                                        }),
                                    }}
                                    onChange={handleDateChangePayer}
                                />
                            </LocalizationProvider>
                            {errors?.birthDatePayer && (
                                <p className="text-red-500">
                                    {String(errors?.birthDatePayer?.message)}
                                </p>
                            )}
                        </div>

                        <div className="w-full sm:w-1/2 px-1 py-3">
                            <TextField
                                defaultValue={""}
                                className="w-full"
                                label="Correo electronico"
                                {...register(`emailPayer`, {
                                    required: "El correo electronico es obligatorio",
                                    pattern: {
                                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                        message: "Ingrese un correo electrónico válido"
                                    }
                                })}
                            />
                            {errors?.emailPayer && (
                                <p className="text-red-500">
                                    {String(errors?.emailPayer?.message)}
                                </p>
                            )}
                        </div>

                        <div className="w-full sm:w-1/2 px-1 py-3">
                            <TextField
                                defaultValue={""}
                                className="w-full"
                                label="Número de celular"
                                {...register(`cellPhonePayer`, {
                                    required: "El número de celular es obligatorio"
                                })}
                                onKeyDown={handleKeyDown}
                            />
                            {errors?.cellPhonePayer && (
                                <p className="text-red-500">
                                    {String(errors?.cellPhonePayer?.message)}
                                </p>
                            )}
                        </div>

                        <div className="w-full sm:w-1/2 px-1 py-3">
                            <FormControl className="w-full">
                                <InputLabel id="genero">Género</InputLabel>
                                <Select
                                    labelId="sexo"
                                    label="sexo"
                                    defaultValue={undefined}
                                    {...register(`sexPayer`, {
                                        required: "El sexo es obligatorio",
                                    })}
                                >
                                    <MenuItem value="M">Masculino</MenuItem>
                                    <MenuItem value="F">Femenino</MenuItem>
                                </Select>
                                {errors?.sexPayer && (
                                    <p className="text-red-500">
                                        {String(errors?.sexPayer?.message)}
                                    </p>
                                )}
                            </FormControl>

                        </div>
                    </div>)
                }

                {/* <div className='w-full sm:w-full px-1 py-3'>
                    <div className="w-full px-1 py-2">
                        <FormControlLabel required control={<div className='flex flex-wrap items-center'>
                            <Checkbox required />Acepto el <div className='text-principal-250 ml-1'>
                                {' '} tratamiento de datos personales
                            </div></div>} label="" />
                    </div>

                    <hr></hr>
                </div> */}

                <div className='flex flex-wrap w-full pt-3'>
                    <span className="w-full font-regular text-md text-principal-250 pb-2">
                        Datos del pago
                    </span>
                </div>


                {rateFound?.valor && serviceDetail?.indOrigen == IND_ORIGEN_EDUCACION && rateFound?.descripcion ?
                    <div className="w-full flex-col">


                        <div className="w-full bg-principal-400/10 overflow-x-scroll">
                            <div
                                className="flex flex-col max-w-full justify-center"
                                dangerouslySetInnerHTML={{ __html: rateFound.descripcion }}
                            ></div>
                            {
                                < div className="py-2 px-2">
                                    <div className="flex w-full flex-wrap">
                                        <span className="text-lg w-full font-bold">Concepto a pagar</span>
                                        <div className="flex w-full  justify-between ">
                                            <span className=" text-principal-750 font-semibold">
                                                {rateFound.nombreConcepto}
                                            </span>
                                            <span className=" text-principal-750 font-semibold">
                                                {(rateFound.valor).toLocaleString("es-CO", {
                                                    style: "currency",
                                                    currency: "COP",
                                                    minimumFractionDigits: 0,
                                                })}
                                            </span>
                                        </div>
                                    </div>
                                    < div className="flex  w-full justify-between ">
                                        <span className="text-lg text-principal-650">
                                            {mensajeCuotas}
                                        </span>
                                        <span className="text-lg text-principal-650">
                                            {(rateFound.total - rateFound.valor).toLocaleString("es-CO", {
                                                style: "currency",
                                                currency: "COP",
                                                minimumFractionDigits: 0,
                                            })}
                                        </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <div className="w-full flex-col">
                        <div className='flex justify-between w-full my-5'>
                            <span className="font-regular text-md text-principal-100 ">
                                Valor a pagar
                            </span>

                            {
                                rateFound?.valor &&
                                <p className='text-principal-100 font-bold text-end'>{rateFound?.valor ? Number(rateFound?.valor).toLocaleString('es-CO', {
                                    style: 'currency',
                                    currency: 'COP',
                                    minimumFractionDigits: 0,
                                }) : ''}</p>
                            }
                        </div>
                    </div>
                }

                {
                    !rateFound?.valor &&
                    <div className='flex justify-center w-full my-5'>
                        <p className='text-principal-100 font-bold text-end'>No se encontraron tarifas para este usuario</p>
                    </div>
                }


                {
                    rateFound?.valor &&
                    <div className='flex justify-center w-full '>
                        <button type="submit" className="bg-principal-250 text-white py-4 w-full rounded-md" disabled={!rateFound?.valor}>Continuar con la compra</button>
                    </div>
                }

                {serviceDetail && serviceDetail.campoJson && serviceDetail.campoJson?.leadFormularioLight == 'S' &&
                    <>
                        <span className='text-principal-150 mt-5'>
                            ¿Tienes dudas y necesitas más información?
                        </span>

                        <div className='flex justify-center w-full mt-2 '>

                            <button type="button" onClick={() => enviarCrmLead()} className="bg-principal-0 border-solid border border-principal-250 text-principal-250 py-4 w-full rounded-md">
                                <HelpOutlineIcon
                                    className="h-6 w-6 mr-2 cursor-pointer text-principal-250 "
                                    style={{ color: 'white' }}
                                />
                                Solicitar información adicional
                            </button>
                        </div>
                    </>}

            </form>
        </div>
    )
}
