
import { useEffect, useState } from 'react'
import requestApi from '../api/cartApi'
import { Business } from 'models/business';

interface BusinessDetails {
    activeBusiness: Business[],
    isLoadingBusiness: boolean,
}

export const useBusiness = () => {

    const urlBusiness = 'negocio';

    const [businessData, setBusinessData] = useState<BusinessDetails>({ activeBusiness: [], isLoadingBusiness: true });

    const getBusinessData = async () => {
        try {
            const business = requestApi.get<Business[]>(`${urlBusiness}/negociosActivos`);

            const businessResp = await Promise.resolve(business);

            setBusinessData({
                activeBusiness: businessResp.data?.sort((a, b) => a.orden - b.orden),
                isLoadingBusiness: false,
            });

        } catch (error) {
            //console.log(error);
        }

    }

    useEffect(() => {
        getBusinessData();
    }, []);

    return {
        ...businessData
    }
}
