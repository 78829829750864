import { Typography } from '@mui/material'
import { MQuotas } from 'models/quotas'
import React from 'react'

interface QuotaCardProps {
    title: string;
    quotas: MQuotas | undefined;
    firstParam?: string;
    secondParam?: string;
    value1Param?: string;
    cupo?: number;
    infoImportante?: string;
}

export const SubsidyCreditCard = ({ quotas, title, firstParam, secondParam, value1Param, cupo, infoImportante }: QuotaCardProps) => {

    return (
        <div className='flex flex-col justify-center items-center w-full md:w-9/20 bg-white border rounded-xl shadow p-8'>
            <p className='font-bold text-principal-500'>{title}</p>
            
            {/* Renderizando firstParam como HTML si es necesario */}
            {firstParam && (
                <span
                    className='text-center'
                    color='primary'
                    dangerouslySetInnerHTML={{ __html: firstParam }}
                />
            )}

            {
                quotas &&
                <div className='py-3'>
                    <Typography className='text-2xl' color='primary'>
                        {Number(cupo).toLocaleString('es-CO', {
                            style: 'currency',
                            currency: 'COP',
                            minimumFractionDigits: 0,
                        })}
                    </Typography>
                </div>
            }

            <span className='text-center text-principal-250 font-bold' color='primary'>
                {value1Param} MV
            </span>

            {/* Renderizando secondParam como HTML si es necesario */}
            {secondParam && (
                <span
                    className='text-center'
                    color='primary'
                    dangerouslySetInnerHTML={{ __html: secondParam }}
                />
            )}

            <div className='flex flex-col w-full py-2'>
                <span className='font-bold' color='primary'>
                    Información importante
                </span>

                {/* Renderizando infoImportante como HTML si es necesario */}
                {infoImportante && (
                    <span
                        className=''
                        color='primary'
                        dangerouslySetInnerHTML={{ __html: infoImportante }}
                    />
                )}
            </div>
        </div>
    )
}
