import { useEffect, useState } from 'react';
import { CampoJson, Service } from 'models/service';
import integrationApi from 'api/integrationApi';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

interface ServicesState {
    upcomingEvents: Service[],
    bestsellersProducts: Service[],
    newsService: Service[],
    interestingProducts: Service[],
    isLoadingService: boolean
}

export const useService = () => {
    const { isLogged } = useSelector((state: RootState) => state.auth);
    const urlService: string = "servicios";

    const [servicesState, setServicesState] = useState<ServicesState>({
        upcomingEvents: [],
        bestsellersProducts: [],
        newsService: [],
        interestingProducts: [],
        isLoadingService: true
    });

    const getCommonServices = async () => {
        setServicesState(prevState => ({
            ...prevState,
            isLoadingService: true
        }));

        const upComingPromise = integrationApi.post<Service[]>(`${urlService}/proximosEventos`, { idMunicipio: null });
        const bestsellersPromise = integrationApi.post<Service[]>(`${urlService}/productosMasVendidos`, { idMunicipio: null });
        const newsPromise = integrationApi.post<Service[]>(`${urlService}/serviciosNovedades`, { idMunicipio: null });

        const responses = await Promise.all([upComingPromise, bestsellersPromise, newsPromise]);

        const upcomingEvents = responses[0]?.data.map(service => {
            const campoJson: CampoJson = JSON.parse("" + service.campoJson);
            service.campoJson = campoJson; // Modificación directa
            return service;
        });

        const bestsellers = responses[1]?.data.map(service => {
            const campoJson: CampoJson = JSON.parse("" + service.campoJson);
            service.campoJson = campoJson;
            return service;
        });

        const news = responses[2]?.data.map(service => {
            const campoJson: CampoJson = JSON.parse("" + service.campoJson);
            service.campoJson = campoJson;
            return service;
        });

        setServicesState(prevState => ({
            ...prevState,
            upcomingEvents: upcomingEvents,
            bestsellersProducts: bestsellers,
            newsService: news,
        }));
    };

    const getInterestingProducts = async () => {
        if (isLogged) {
            const interestingPromise = await integrationApi.post<Service[]>(`${urlService}/productosDeInteres`, { idMunicipio: null });

            const interestingProducts = interestingPromise?.data.map(service => {
                const campoJson: CampoJson = JSON.parse("" + service.campoJson);
                service.campoJson = campoJson;
                return service;
            });

            setServicesState(prevState => ({
                ...prevState,
                interestingProducts: interestingProducts,
                isLoadingService: false // Asegúrate de que se actualice el estado de carga
            }));
        } else {
            setServicesState(prevState => ({
                ...prevState,
                isLoadingService: false // Asegúrate de que se actualice el estado de carga
            }));
        }
    };

    useEffect(() => {
        getCommonServices(); // Llama esta función una vez
    }, []);

    useEffect(() => {
        getInterestingProducts(); // Llama a esta función solo cuando isLogged cambie
    }, [isLogged]);

    return {
        ...servicesState
    };
};
