import {
  Boy,
  CalendarToday,
  Delete,
  EmojiPeople,
  EscalatorWarning,
  ExpandMore,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { MHotelReservation } from "models/hotelReservationsResponse";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ReservationCardProps {
  sale: MHotelReservation;
  handleOpenDialog: any;
  handlePay: any;
}

export const ReservationCard = ({
  sale,
  handleOpenDialog,
  handlePay,
}: ReservationCardProps) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [cantidadPersonas, setCantidadPersonas] = useState(0);

  const onPay = () => {
    try {
      setIsLoading(prev => !prev)
      handlePay(sale.idCompra)
    } catch {
      setIsLoading(prev => !prev)
    }
  }


  useEffect(() => {
    moment.locale("es");

    if (sale) {
      let adultos: number = sale.adultos ? +sale.adultos : 0;
      let ninos: number = sale.ninios ? +sale.ninios : 0;
      let total = adultos + ninos;
      setCantidadPersonas(sale.totalReservas);
    }

  }, []);

  return (
    <div
      className="flex flex-wrap w-full rounded-lg border p-4 border-s-8 items-center justify-center"
      style={{ borderLeftColor: sale.color }}
    >
      <div className="flex flex-col w-full sm:w-2/3 m-0 text-principal-650 ">
        <p className="font-bold " style={{ color: sale.color }}>
          {sale?.nombreNegCat}
        </p>
        <div
          className="cursor-pointer"
          onClick={() =>
            navigate(`/detalle-servicio/${sale.idServicio}/${sale.idCompra}`)
          }
        >
          {sale?.nombreCentroServicio}
        </div>
        <div>{sale?.nombreServCentro}</div>
        <div className="py-1">
          <CalendarToday fontSize="small" className="mr-1" />
          <span className="d-flex mb-2 align-items-center">
            {moment(sale.fechaIngreso).format("dddd DD [de] MMMM YYYY")} - {moment(sale.fechaSalida).format("dddd DD [de] MMMM YYYY")}
          </span>
        </div>
        <div className="flex flex-row py-1">
          <p className="pr-2 font-bold">Valor:</p>
          <p>
            {sale?.valorTotal.toLocaleString("es-CO", {
              style: "currency",
              currency: "COP",
              minimumFractionDigits: 0,
            })}
          </p>
        </div>
        <div className="flex flex-row py-1">
          <p className="pr-2 font-bold">Cantidad de personas:</p>
          <p>
            {cantidadPersonas}
          </p>
        </div>


        <div className="flex flex-row py-1">
          <p className="pr-2 font-bold">Estado de la compra:</p>
          <p className="font-bold" style={{ color: sale.color }}>
            {sale?.nombreEstado}
          </p>
        </div>
        <div className="flex flex-row py-1">
          <p className="pr-2 font-bold">Oportunidad:</p>
          <p>{sale?.idOportunidad}</p>
        </div>
        {sale?.numeroReserva && (
          <div className="flex flex-row py-1">
            <p className="pr-2 font-bold">Numero reserva:</p>
            <p>{sale?.numeroReserva}</p>
          </div>
        )}
      </div>
      <div className="flex flex-wrap justify-center items-center w-full sm:w-1/3 py-2 m-0 px-2 h-fit  ">
        <LoadingButton
          variant="contained"
          className="h-min px-6 mr-2"
          onClick={onPay}
          loading={isLoading}
        >
          Continuar compra
        </LoadingButton>
        <IconButton
          aria-label="Eliminar"
          onClick={() => handleOpenDialog(sale.idCompra)}
        >
          <Delete color="error" />
        </IconButton>
      </div>
    </div>
  );
};
