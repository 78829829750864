import integrationApi from 'api/integrationApi';

export const useReCaptcha = () => {


    const validateTokenRecaptcha = async (token: string) => {
        try {
            let executeRecaptcha = {
                "token": token
            }

            const validateTokenRecaptchaRequest = await integrationApi.post<any>(
                `auth/validarTokenRecaptcha`,
                executeRecaptcha,
            );

            const validateTokenRecaptchaResp = await Promise.resolve(validateTokenRecaptchaRequest);
            return validateTokenRecaptchaResp?.data;
        } catch (error) {
            //console.log(error);
        }

    }

    return {
        validateTokenRecaptcha
    }

}