import React, { useEffect, useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Checkbox, FormControlLabel } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { AdmissionData, CompraUsuario, ParticularForm, RateFormLight, ServiceDetailLight, ShoppingCartWithValue } from 'models/lightForm';
import useInfoSolicitante from 'hooks/useInfoSolicitante';
import { InfoSolicitante } from 'models/infoCotizante';
import { useRates } from 'hooks/useRates';
import { RatesFormLightRequest } from 'hooks/ratesFormLightRequest';
import { getAgeFromDateString } from 'utils/FunctionUtilities';
import { useShoppingCar } from 'hooks/useShoppingCar';
import { useUser } from 'hooks/useUser';
import { EnvioCrmLeadRequest } from '../../models/lightForm';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setSuccess } from 'store/slices/status';
import { useParam } from 'hooks/useParam';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { setFormLightData } from 'store/slices/auth/formLightSlice';
import { Loading } from 'components/Loading';
import { set } from 'date-fns';
import { IND_ORIGEN_EDUCACION, PARAM_MENSAJE_CUOTAS_EDUCACION } from 'utils/constants';


interface ShoppingDetailProps {
    setStep: React.Dispatch<React.SetStateAction<number>>,
    admissionData?: AdmissionData,
    serviceDetail?: ServiceDetailLight,
    setRateSelected?: React.Dispatch<React.SetStateAction<any>>,
    setPreviousstep: React.Dispatch<React.SetStateAction<number>>,
    cotizante?: InfoSolicitante,
    setCotizante?: React.Dispatch<React.SetStateAction<any>>,
    handleFormSubmit: SubmitHandler<any>
    rateSelected?: RateFormLight,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    isLoading: boolean
}

const ShoppingDetail = ({ setStep, admissionData, serviceDetail, setRateSelected, setPreviousstep, cotizante, setCotizante, handleFormSubmit, rateSelected, setLoading, isLoading }: ShoppingDetailProps) => {

    //console.log(admissionData)


    const dispatch = useDispatch<AppDispatch>();

    const interviewDataFormLight = useSelector((state: RootState) => state.formLight.interviewData);

    const { getParamData } = useParam();

    const { handleSubmit, register, formState: { errors }, setValue } = useForm<ParticularForm>();

    const { getInfoSolicitante } = useInfoSolicitante();

    const { getRatesFormLight } = useRates();

    const [rateFound, setrateFound] = useState<RateFormLight>();

    const { envioCrmLead } = useUser();

    const { shoppingCartWithValueLightForm, paySale } = useShoppingCar();

    const [mensajeCuotas, setMensajeCuotas] = useState('');

    useEffect(() => {
        setPreviousstep(4)

        getPaymentResponsible();

        findRate();

    }, []);
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        // handleFormSubmitV2(null);
    };

    const handleFormSubmitV2: SubmitHandler<any> = async (
    ) => {
        setLoading(true);
        if (cotizante && (!cotizante.email || cotizante.email.length === 0)) {
            setLoading(false);
            handleClickOpen();
        } else {
            if (Object.keys(errors).length === 0 && rateSelected && serviceDetail && admissionData) {

                handleFormSubmit(null);
            }

        }

    };

    useEffect(() => {
        const getParam = async () => {
            const mensajeCuotasParam = await getParamData(PARAM_MENSAJE_CUOTAS_EDUCACION);
            mensajeCuotasParam?.data?.valorParametro &&
                setMensajeCuotas(mensajeCuotasParam?.data?.valorParametro);
        }

        getParam();
    }, []);


    const getPaymentResponsible = async () => {
        if (admissionData) {
            const infoSolicitanteRequest: InfoSolicitante = await getInfoSolicitante(
                {
                    tipoIdentificacion: admissionData?.tipoDocumento,
                    numeroIdentificacion: admissionData?.numeroDocumento
                }
            );

            //console.log('info cotizante')
            //console.log(infoSolicitanteRequest)

            if (setCotizante) {
                setCotizante(infoSolicitanteRequest);
            }
        }

    }

    const findRate = async () => {
        setLoading(true);
        //console.log('find rate')
        //console.log(serviceDetail)
        //console.log(admissionData)
        if (serviceDetail && admissionData) {


            let fechaServicio: Date = serviceDetail.fechaServicio;

            //console.log('start consultar')
            if (fechaServicio) {
                //console.log('Start fecha servicio', fechaServicio)

                // fecha a string dia/mes/año
                let fechaServicioString: string = fechaServicio.getDate() + '/' + (fechaServicio.getMonth() + 1) + '/' + fechaServicio.getFullYear();


                let rateRequest: RatesFormLightRequest = {
                    idServicio: serviceDetail?.idServicio,
                    numeroIdentificacion: admissionData?.numeroDocumento,
                    tipoIdentificacion: admissionData?.tipoDocumento,
                    fechaNacimiento: admissionData?.fechaNacimiento,
                    fechaServicio: fechaServicioString
                }



                const getRatesFound: RateFormLight = await getRatesFormLight(rateRequest);
                setLoading(false);
                if (getRatesFound) {
                    setrateFound(getRatesFound);
                    setRateSelected && setRateSelected(getRatesFound);
                }
                //console.log('rates found');
                //console.log(getRatesFound);
            }
        } else setLoading(false);

    }

    const enviarCrmLead = async () => {

        if (admissionData && rateFound && serviceDetail) {


            const cotizanteDTO = cotizante?.cotizanteDto;

            let envioCrmLeadRequest: EnvioCrmLeadRequest = {
                tipoIdentificacionAfiliado: admissionData?.tipoDocumento,
                numeroIdentificacionAfiliado: admissionData?.numeroDocumento,
                nombreAfiliado: admissionData?.nombre,
                fechaNacimientoAfiliado: admissionData?.fechaNacimiento,
                celularAfiliado: admissionData?.celular,
                correoAfiliado: admissionData?.email,
                idServicio: serviceDetail?.idServicio,
                numeroIdentificacionAcudiente: admissionData?.numeroDocumento,
                tipoIdentificacionAcudiente: admissionData?.tipoDocumento,
                nombreAcudiente: admissionData?.nombre,
                celularAcudiente: admissionData?.celular,
                correoAcudiente: admissionData?.email,
                fechaNacimientoAcudiente: admissionData?.fechaNacimiento
            }

            if (cotizanteDTO) {
                envioCrmLeadRequest.numeroIdentificacionAfiliado = cotizanteDTO.identificacion;
                envioCrmLeadRequest.tipoIdentificacionAfiliado = cotizanteDTO.abreviatura;
                envioCrmLeadRequest.nombreAfiliado = cotizanteDTO.nombre;
                envioCrmLeadRequest.celularAfiliado = cotizante.celular;
                envioCrmLeadRequest.correoAfiliado = cotizante.email;
            }
            const envioCrmResponse = await envioCrmLead(envioCrmLeadRequest);

            if (envioCrmResponse) {
                dispatch(setSuccess("Se ha enviado la información de contacto correctamente"));
                setStep(0);
            }
        }

    }

    return (
        <div className="flex flex-wrap w-full">
            <span className="flex w-full font-bold text-xl text-principal-250 pb-2 align-center justify-center items-center content-center">
                Detalles de la compra
            </span>
            <form
                onSubmit={handleSubmit(handleFormSubmitV2)}
                className="w-full justify-center"
            >
                <div className='flex flex-row'>

                    <div className='flex flex-col w-full md:w-1/2 sm:w-full'>
                        <span className="w-full font-regular text-md text-principal-250 pb-2">
                            Datos del solicitante del servicio
                        </span>

                        <div className='flex flex-col'>
                            <span>
                                Nombre
                            </span>

                            <span className='text-principal-100 mt-2'>
                                {admissionData?.nombre}
                            </span>
                        </div>

                        <div className='flex flex-col mt-4'>
                            <span>
                                Tipo de documento
                            </span>

                            <span className='text-principal-100 mt-2'>
                                {admissionData?.tipoDocumento}
                            </span>
                        </div>

                        <div className='flex flex-col mt-4'>
                            <span>
                                Número de identificación
                            </span>

                            <span className='text-principal-100 mt-2'>
                                {admissionData?.numeroDocumento}
                            </span>
                        </div>
                    </div>

                </div>


                {/* <div className='w-full sm:w-full px-1 py-3'>
                    <div className="w-full px-1 py-2">
                        <FormControlLabel required control={<div className='flex flex-wrap items-center'>
                            <Checkbox required />Acepto el <div className='text-principal-250 ml-1'>
                                {' '} tratamiento de datos personales
                            </div></div>} label="" />
                    </div>

                    <hr></hr>
                </div> */}

                <div className='flex flex-wrap w-full pt-3'>
                    <span className="w-full font-regular text-md text-principal-250 pb-2">
                        Datos del pago
                    </span>
                </div>


                {rateFound?.valor &&
                    <div className="w-full flex-col">
                        {serviceDetail && (serviceDetail.indOrigen !== IND_ORIGEN_EDUCACION || !rateFound.descripcion) && (
                            <div className='flex justify-between w-full my-5'>
                                <span className="font-regular text-md text-principal-100 ">
                                    Valor a pagar
                                </span>

                                {
                                    rateFound?.valor &&
                                    <p className='text-principal-100 font-bold text-end'>{rateFound?.valor ? Number(rateFound?.valor).toLocaleString('es-CO', {
                                        style: 'currency',
                                        currency: 'COP',
                                        minimumFractionDigits: 0,
                                    }) : ''}</p>
                                }
                            </div>
                        )}



                        {rateFound.descripcion && (
                            <div className="w-full bg-principal-400/10 overflow-x-scroll">
                                <div
                                    className=" flex flex-col scale-90 md:scale-100"
                                    dangerouslySetInnerHTML={{ __html: rateFound.descripcion }}
                                ></div>
                                <div className="flex w-full flex-wrap py-2 px-2">
                                    <span className="text-lg w-full font-bold">Concepto a pagar</span>
                                    <div className="flex w-full  justify-between ">
                                        <span className=" text-principal-750 font-semibold">
                                            {rateFound.nombreConcepto}
                                        </span>
                                        <span className=" text-principal-750 font-semibold">
                                            {(rateFound.valor).toLocaleString("es-CO", {
                                                style: "currency",
                                                currency: "COP",
                                                minimumFractionDigits: 0,
                                            })}
                                        </span>
                                    </div>
                                </div>
                                < div className="flex py-2 px-2 w-full justify-between">
                                    <span className="text-lg text-principal-650">
                                        {mensajeCuotas}
                                    </span>
                                    <span className="text-lg text-principal-650">
                                        {(rateSelected!.total - rateSelected!.valor).toLocaleString("es-CO", {
                                            style: "currency",
                                            currency: "COP",
                                            minimumFractionDigits: 0,
                                        })}
                                    </span>
                                </div>
                            </div>
                        )}

                    </div>
                }

                {
                    !rateFound?.valor &&
                    <div className='flex justify-center w-full my-5'>
                        <p className='text-principal-100 font-bold text-end'>No se encontraron tarifas para este usuario</p>
                    </div>
                }
                {
                    rateFound?.valor &&
                    <div className='flex justify-center w-full '>
                        <button type="submit" className="bg-principal-250 text-white py-4 w-full rounded-md" disabled={!rateFound?.valor}>Continuar con la compra</button>
                    </div>
                }
                {serviceDetail && serviceDetail.campoJson && serviceDetail.campoJson?.leadFormularioLight == 'S' &&
                    <>
                        <span className='flex text-principal-150 mt-6'>
                            ¿Tienes dudas y necesitas más información?
                        </span>

                        <div className='flex justify-center w-full mt-2 '>

                            <button type='button' onClick={() => enviarCrmLead()} className="bg-principal-0 border-solid border border-principal-250 text-principal-250 py-4 w-full rounded-md">
                                <HelpOutlineIcon
                                    className="h-6 w-6 mr-2 cursor-pointer text-principal-250 "
                                    style={{ color: 'white' }}
                                />
                                Solicitar información adicional
                            </button>
                        </div>
                    </>}

            </form>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const email = formJson.email;
                        if (cotizante) {
                            cotizante.email = email;
                        }
                        handleFormSubmit(null);
                        handleClose();
                    },
                }}
            >
                <DialogContent>
                    <DialogContentText>
                        Señor usuario, no tenemos registrado tu correo electronico en nuestro sistema, te invitamos a digitarlo para efectos de factura electronica.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button type="submit">Continuar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ShoppingDetail;
