import { useState } from 'react';
import integrationApi from '../api/integrationApi';
import { UpdateDateRequest, UpdateDateResponse } from 'models/UpdateProfileForm';
import { InfoUsuarioFormLight } from '../models/infoUsuarioFormLight';
import { EnvioCrmLeadRequest } from 'models/lightForm';

export const useUser = () => {
    const [isLoadingUpdateData, setIsLoadingUpdateData] = useState(false);

    const urlUpdateUser = 'usuario';

    const updateUserData = async (updateDataRequest: UpdateDateRequest) => {
        setIsLoadingUpdateData(true);

        const updateData = await integrationApi.post<UpdateDateResponse>(
            `${urlUpdateUser}/actualizarDatos`,
            updateDataRequest,
        );

        setIsLoadingUpdateData(false);

        return updateData?.data;
    };


    const getInfoUsuarioFormLight = async (infoUsuarioFormLight: InfoUsuarioFormLight) => {

        try {

            const infoUsuarioFormLightRequest = await integrationApi.post<any>(
                `${urlUpdateUser}/infoUsuarioFormLight`,
                infoUsuarioFormLight,
            );

            const infoUsuarioFormLightResp = await Promise.resolve(infoUsuarioFormLightRequest);

            return infoUsuarioFormLightResp?.data;
        } catch (error) {
            //console.log(error);
        }

    }

    const envioCrmLead = async (envioCrmLeadRequest: EnvioCrmLeadRequest) => {

        try {

            const envioCrmLeadRequestRequestWeb = await integrationApi.post<any>(
                `${urlUpdateUser}/envioCrmLead`,
                envioCrmLeadRequest,
            );

            const infoCrmLeadResponse = await Promise.resolve(envioCrmLeadRequestRequestWeb);

            return infoCrmLeadResponse?.data;
        } catch (error) {
            //console.log(error);
        }

    }


    const consultaInfoFormParticular = async (tipoIdentificacion: string, identificacion: string) => {

        const infoFormParticularRequest = await integrationApi.post<any>(
            `${urlUpdateUser}/consultaInfoFormParticular`,
            { tipoIdentificacion: tipoIdentificacion, identificacion: identificacion }
        );

        const infoFormParticularResponse = await Promise.resolve(infoFormParticularRequest);

        return infoFormParticularResponse?.data;
    }




    return {
        isLoadingUpdateData,
        updateUserData,
        getInfoUsuarioFormLight,
        envioCrmLead,
        consultaInfoFormParticular
    };
};