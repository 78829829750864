import { Assignment, AssignmentInd, CheckBox, HourglassTop, IndeterminateCheckBox, Payments } from '@mui/icons-material';
import { Step, StepLabel, Stepper } from '@mui/material';
import React, { useEffect, useState } from 'react'

interface HousingSubsidyStepperProps {
    currentStep: number;
}

export const HousingSubsidyStepper = ({ currentStep }: HousingSubsidyStepperProps) => {
    ////console.log(currentStep)
    const steps: any = [
        { icon: Assignment, label: 'Postulado' },
        { icon: IndeterminateCheckBox, label: 'Por verificar' },
        { icon: CheckBox, label: 'Calificado' },
        { icon: AssignmentInd, label: 'Asignado' },
        { icon: HourglassTop, label: 'En proceso de pago' },
        { icon: Payments, label: 'Pagado' },
    ];

    const [mobile, setMobile] = useState(window.innerWidth <= 1023);

    const handleWindowSizeChange = () => {
        setMobile(window.innerWidth <= 1023);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    return (
        <Stepper activeStep={currentStep} className='w-full center' orientation={mobile ? 'horizontal' : 'vertical'} >
            {steps.map((step: any, index: any) => {
                return (
                    <Step key={step.label}>
                        <StepLabel color={index <= currentStep ? 'primary' : 'disabled'} StepIconProps={{ color: index <= currentStep ? 'primary' : 'disabled' }} StepIconComponent={step?.icon}>
                            {step.label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    )
}
