import axios from "axios";
import { API_URL } from "config";
import { ErrorResponse } from "models/errorResponse";
import { store } from "store";
import { setError } from "store/slices/status";
import { SECURITY_URLS } from "utils/constants";
import { decrypt, encrypt } from "utils/security/AesUtil";

const CryptoJS = require("crypto-js");

const getAccessToken = () => {
  const state = store.getState();
  return state.auth.authData?.token
    ? state.auth.authData?.token
    : state.jwt.token.token;
};

const setErrorMessage = (errorMessage: string) => {
  store.dispatch(setError(errorMessage));

  setTimeout(() => {
    store.dispatch(setError(null));
  }, 6000);
};

const cartBaseUrl = axios.create({
  baseURL: `${API_URL}`,
});

cartBaseUrl.interceptors.request.use(
  // Verificar si la solicitud tiene un body y si es necesario encriptarlo
  async (config) => {
    const token = getAccessToken();

    config.headers = Object.assign({}, config.headers, {
      Authorization: token,
      "Content-Type": "application/json",
    });

    const cipherText = encrypt(JSON.stringify(config.data));
    config.data = cipherText;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

cartBaseUrl.interceptors.response.use(
  (response) => {
    response.data = decrypt(response.data);

    return response;
  },
  (error) => {
    if (axios.isAxiosError(error)) {
      const url = error?.response?.config.url?.split("/")?.at(-1);

      let errorMessage: ErrorResponse;

      errorMessage = decrypt(error.response?.data as string) as ErrorResponse;
      setErrorMessage(errorMessage.message);
    } else {
      ////console.log(error);
    }
  }
);
export default cartBaseUrl;
