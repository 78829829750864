import React, { useEffect } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { AdmissionData, CodeForm, ServiceDetailLight } from 'models/lightForm';
import UseTotp from 'hooks/useTotp';
import { GenerateTotpRequest, ValidateTotpRequest } from 'models/totpServiceModel';

interface TotpFormProps {
    setStep: React.Dispatch<React.SetStateAction<number>>,
    viaMessage: string,
    admissionData?: AdmissionData,
    setPreviousstep: React.Dispatch<React.SetStateAction<number>>,
    serviceDetail?: ServiceDetailLight
}

export const TotpForm = ({ setStep, viaMessage, admissionData, setPreviousstep, serviceDetail }: TotpFormProps) => {

    const { register, handleSubmit, formState: { errors } } = useForm<CodeForm>();

    const { generateTotp, validateTotp } = UseTotp();

    useEffect(() => {
        setPreviousstep(4)

        sendTotp();
    }, []);

    const onSubmitHandler = async (data: any) => {


        // Llamar a la función onSubmit pasándole el código TOTP

        if (data?.code && admissionData) {
            let code = data.code;

            let dataValidateTotp: ValidateTotpRequest = {
                numeroIdentificacion: admissionData?.numeroDocumento,
                tipoIdentificacion: admissionData?.tipoDocumento,
                celular: admissionData?.celular,
                totp: code
            }

            const validateTotpResponse = await validateTotp(dataValidateTotp);

            if (validateTotpResponse) {
                if (serviceDetail?.indOrigen === 'E' && serviceDetail?.campoJson.servicioIngles === "S" && serviceDetail.campoJson.tieneEntrevista === "S") {
                    setStep(6);
                } else {
                    setStep(5);
                }

            }

        }
    };


    const sendTotp = async () => {


        if (admissionData) {
            let dataSendTotp: GenerateTotpRequest = {
                numeroIdentificacion: admissionData?.numeroDocumento,
                tipoIdentificacion: admissionData?.tipoDocumento,
                email: admissionData?.email,
                celular: admissionData?.celular,
            };
            if (viaMessage === 'EMAIL') {
                dataSendTotp.tipoTotp = "E";
            }

            if (viaMessage === "SMS") {
                dataSendTotp.tipoTotp = "C";
            }

            //console.log(dataSendTotp)

            const generateTotpService = await generateTotp(dataSendTotp);

        }

    }


    return (
        <div className='w-full'>

            <span className="flex w-full font-regular text-xl text-principal-250 justify-center content-center items-center">
                Digita el código
            </span>

            <div className='my-3'>
                <span className=''>Ingresa el codigo que enviamos a <span className='text-principal-250'>{admissionData?.email && viaMessage === 'EMAIL' ? admissionData.email : viaMessage === 'SMS' && admissionData?.celular ? admissionData.celular : ''}</span></span>
            </div>
            <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="w-full sm:w-full px-1 py-3">
                    <TextField
                        defaultValue={""}
                        className="w-full"
                        type='number'
                        label="Codigo de autenticación"
                        {...register(`code`, {
                            required: "El código es requerido"
                        })}
                    />
                    {errors?.code && (
                        <p className="text-red-500">
                            {String(errors?.code?.message)}
                        </p>
                    )}
                </div>

                <div className='flex justify-center w-full '>
                    <button
                        type="submit"
                        className="bg-principal-250 w-full text-white py-2 px-4 rounded-md"
                    >Validar código</button>
                </div>

                <div className='my-4'>
                    <span className='text-center text-principal-100'>¿No has recibido el código de autenticación?
                        <span className='text-principal-250 cursor-pointer' onClick={() => sendTotp()}>{' '}Solicita un nuevo código</span></span>
                </div>
            </form>
        </div>
    )
}
