import { useEffect, useState } from "react";
import requestApi from '../api/cartApi'
import { Parameter } from "models/parameter";
import { COMPRA_DE_CARTERA_PARRAFO, COMPRA_SERVICIOS_PRIMER_PARRAFO, COMPRA_SERVICIOS_SEGUNDO_PARRAFO, COMPRA_SERVICIO_MV_A, COMPRA_SERVICIO_MV_B, CUPO_CREDITO_MV_A, CUPO_CREDITO_MV_B, CUPO_CREDITO_PRIMER_PARRAFO, CUPO_CREDITO_SEGUNDO_PARRAFO, INFO_IMPORTANTE_COMPRA, INFO_IMPORTANTE_CREDITO, LIBRE_INVERSION_PARRAFO } from "utils/constants";
import integrationApi from 'api/integrationApi';
import { decrypt } from "utils/security/AesUtil";


export const useCreditOffersParams = () => {

    const [isLoadingService, setIsLoadingService] = useState(true);
    const [subsidyCreditParams, setSubsidyCreditParams] = useState({
        creditBuyFirstParagraph: '',
        creditBuySecondParagraph: '',
        quotaFirstParagraph: '',
        quotaSecondParagraph: '',
        cupoCreditoMv: '',
        compraServicioMv: '',
        infoImportanteCredito: '',
        infoImportanteCompra: '',
    });

    const [goodwillCreditParams, setGoodwillCreditParams] = useState({
        freeInvestment: '',
        portfolioPurchase: '',
    });

    const urlParam = 'parametro';

    const urlCredit = 'credit'

    const getParamData = async () => {
        try {
            const userData =
                localStorage.getItem("userData") &&
                decrypt(localStorage.getItem("userData")!);

            const categoria = userData.afiliado?.categoria;
            console.log(categoria);
        
            let cupoCreditoKey, compraServicioKey;
        
            // Si la categoría es 'A' o no está definida, usa las claves de la categoría 'A'
            if (categoria === 'A' || !categoria) {
                cupoCreditoKey = CUPO_CREDITO_MV_A;
                compraServicioKey = COMPRA_SERVICIO_MV_A;
            } else if (categoria === 'B') {
                // Si la categoría es 'B', usa las claves de la categoría 'B'
                cupoCreditoKey = CUPO_CREDITO_MV_B;
                compraServicioKey = COMPRA_SERVICIO_MV_B;
            }
             

            const creditBuyFirstParagraphRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${COMPRA_SERVICIOS_PRIMER_PARRAFO}`);
            const creditBuySecondParagraphRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${COMPRA_SERVICIOS_SEGUNDO_PARRAFO}`);
            const quotaFirstParagraphRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${CUPO_CREDITO_PRIMER_PARRAFO}`);
            const quotaSecondParagraphRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${CUPO_CREDITO_SEGUNDO_PARRAFO}`);
            const freeInvestmentRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${LIBRE_INVERSION_PARRAFO}`);
            const portfolioPurchaseRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${COMPRA_DE_CARTERA_PARRAFO}`);
            // Realiza las solicitudes con los valores de clave configurados
            const cupoCreditoMvRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${cupoCreditoKey}`);
            const compraServicioMvRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${compraServicioKey}`);
            const infoImportanteCreditoRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${INFO_IMPORTANTE_CREDITO}`);
            const infoImportanteCompraRequest = await requestApi.get<Parameter>(`${urlParam}/consultarLlave?key=${INFO_IMPORTANTE_COMPRA}`);


            const creditBuyFirstParagraph = creditBuyFirstParagraphRequest?.data?.valorParametro;
            const creditBuySecondParagraph = creditBuySecondParagraphRequest?.data?.valorParametro;
            const quotaFirstParagraph = quotaFirstParagraphRequest?.data?.valorParametro;
            const quotaSecondParagraph = quotaSecondParagraphRequest?.data?.valorParametro;
            const freeInvestment = freeInvestmentRequest?.data?.valorParametro;
            const portfolioPurchase = portfolioPurchaseRequest?.data?.valorParametro;
            const cupoCreditoMv = cupoCreditoMvRequest?.data?.valorParametro;
            const compraServicioMv = compraServicioMvRequest?.data?.valorParametro;
            const infoImportanteCredito = infoImportanteCreditoRequest?.data?.valorParametro;
            const infoImportanteCompra = infoImportanteCompraRequest?.data?.valorParametro;

            setSubsidyCreditParams({
                creditBuyFirstParagraph,
                creditBuySecondParagraph,
                quotaFirstParagraph,
                quotaSecondParagraph,
                cupoCreditoMv,
                compraServicioMv,
                infoImportanteCredito,
                infoImportanteCompra
            })

            setGoodwillCreditParams({
                freeInvestment,
                portfolioPurchase
            })

            setIsLoadingService(false);
        } catch (error) {
            //console.log(error);
        }
    }

    useEffect(() => {
        getParamData();
    }, [])


    const getCreditOffers = async () => {
        try {

            const requestCreditOffer = integrationApi.get<any>(`${urlCredit}/consultaCreditoUsuario`);

            const creditResponse = await Promise.resolve(requestCreditOffer);

            return creditResponse.data;

        } catch (error) {
        }
    }


    return {
        goodwillCreditParams,
        subsidyCreditParams,
        isLoadingService,
        getCreditOffers
    }
}