import { Loading } from "components/Loading";
import { useRedirect } from "hooks/useRedirect";
import { sessionRequest } from "models/UpdateProfileForm";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const Profile = () => {
  const { authData, authSession } = useSelector((state: RootState) => state.auth);
  const credentials = useSelector(
    (state: RootState) => state.credentials.credentials
  );
  const { isLoadingUrlUpdateUser, getUrlUpdateUser } = useRedirect();
  const [url, setUrl] = useState("");

  const handleUpdateUserData = async (data: any) => {
    if (!data) return
    const request: sessionRequest = {
      accessToken: data["comfenApp/acessToken"],
      uid: data["comfenApp/firebaseId"],
      firebaseId: data["comfenApp/auth_id"],
      appId: credentials.ecommerceAppId,
      email: data["comfenApp/email"],
      tipoIdentificacion: data["comfenApp/tipo_identificacion"],
      numeroIdentificacion: data["comfenApp/rest_id"],
    };
    const respuesta = await getUrlUpdateUser(request);
    setUrl(respuesta?.url);
    ////console.log("response: ", respuesta);

  };

  useEffect(() => {
    handleUpdateUserData(authSession);
  }, [authSession]);




  return (
    <div className='w-full rounded border border-principal-350 shadow'>
      {isLoadingUrlUpdateUser && !url ? <Loading /> :
        <iframe
          src={url!}
          title={"Embedded perfil"}
          width={"100%"}
          height={"850px"}
          allowFullScreen={true}
        />
      }

    </div>

  );
};
